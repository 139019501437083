import React, { useEffect, useRef } from 'react';
import './App.css';  // Custom CSS for styling the clock (explained below)

function CountdownTracker(label, value) {
  const el = document.createElement('span');
  el.className = 'flip-clock__piece';
  el.innerHTML = `
    <b class="flip-clock__card card">
      <b class="card__top"></b>
      <b class="card__bottom"></b>
      <b class="card__back"><b class="card__bottom"></b></b>
    </b>
    <span class="flip-clock__slot">${label}</span>
  `;

  this.el = el;
  const top = el.querySelector('.card__top');
  const bottom = el.querySelector('.card__bottom');
  const back = el.querySelector('.card__back');
  const backBottom = el.querySelector('.card__back .card__bottom');

  this.update = function(val) {
    val = ('0' + val).slice(-2);
    if (val !== this.currentValue) {
      if (this.currentValue >= 0) {
        back.setAttribute('data-value', this.currentValue);
        bottom.setAttribute('data-value', this.currentValue);
      }
      this.currentValue = val;
      top.innerText = this.currentValue;
      backBottom.setAttribute('data-value', this.currentValue);

      this.el.classList.remove('flip');
      void this.el.offsetWidth;
      this.el.classList.add('flip');
    }
  };

  this.update(value);
}

function getTimeSinceBirth(birthDateTime) {
  const now = new Date();
  const birthDate = new Date(birthDateTime);

  let seconds = Math.floor((now - birthDate) / 1000);
  const years = Math.floor(seconds / (365 * 24 * 3600));
  seconds %= 365 * 24 * 3600;
  const days = Math.floor(seconds / (24 * 3600));
  seconds %= 24 * 3600;
  const hours = Math.floor(seconds / 3600);
  seconds %= 3600;
  const minutes = Math.floor(seconds / 60);
  seconds %= 60;

  return { years, days, hours, minutes, seconds };
}

function Clock({ birthDateTime }) {
  const clockRef = useRef(null);

  useEffect(() => {
    const el = document.createElement('div');
    el.className = 'flip-clock';

    const trackers = {};
    const initialTime = getTimeSinceBirth(birthDateTime);

    for (let key in initialTime) {
      trackers[key] = new CountdownTracker(key, initialTime[key]);
      el.appendChild(trackers[key].el);
    }

    clockRef.current.appendChild(el);

    function updateClock() {
      const currentTime = getTimeSinceBirth(birthDateTime);
      for (let key in trackers) {
        trackers[key].update(currentTime[key]);
      }
      requestAnimationFrame(updateClock);
    }

    requestAnimationFrame(updateClock);

    return () => {
      clockRef.current.removeChild(el);
    };
  }, [birthDateTime]);

  return <div ref={clockRef} />;
}

export default function App() {
  return (
    <div>
      <h1 className='hedd'>Time Wasted</h1>
      <Clock birthDateTime="2002-10-31T15:45:00" />
    </div>
  );
}